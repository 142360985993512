/* stripe  */

.register_Payment {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* Ensure the blur effect stays within the container */
}

.register_animate_text {
  /* border-right: 5px solid #ff000085 !important; */
  /* background: transparent linear-gradient(48deg, rgba(11, 1, 2, 0) 0%, #f98e2d 100%); */
  text-align: start;
  align-items: center;
}

.padding_right_col {
  padding: 0px 0px 0px 30px;
}

.register_Payment::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  backdrop-filter: blur(10px);
  opacity: 0.5;
  /* Adjust the opacity as needed */
}

/* Content styles */
.register_content {
  position: relative;
  z-index: 1;
  /* Ensure content is above the background */
}

.paymentstripe_with_css {
  /* border: 2px solid #d5862b; */
  padding: 5px 10px 5px 10px;
  margin: 20px;
  width: 500px;
  /* background-color: #d5862b; */
  border-radius: 10px;
}

.start_payment_css {
  width: 100px;
  font-size: 22px;
  font-weight: 600;
  border-radius: 5px;
}

.paymentrazor_with_css {
  /* border: 2px solid #f67f0d; */
  padding: 10px;
  margin: 20px;
  width: 500px;
  /* background-color: #f67f0d; */
  border-radius: 10px;
}

.paymentpaypal_with_css {
  /* border: 2px solid #6a19c0; */
  padding: 10px;
  margin: 20px;
  width: 500px;
  /* background-color: #6a19c0; */
  border-radius: 10px;
}

.paymentiyzico_with_css {
  border: 2px solid #1e64ff;
  padding: 10px;
  margin: 20px;
  width: 500px;
  background-color: rgb(248, 190, 55);
  border-radius: 10px;
}

.paymentpaystack_with_css {
  border: 2px solid #3c0df6;
  padding: 10px;
  margin: 20px;
  width: 500px;
  background-color: #3c0df6;
  border-radius: 10px;
}

.paymenttabheading {
  font-size: 15px;
}

.subcription_live_css {
  width: 100%;
  color: #f60553;
  height: 5px;
  background: linear-gradient(to right, #f60553, #0000);
  /* background: #f60553; */
}


.option-card {
  border: 2px solid transparent;
  padding: 15px;
  border-radius: 10px;
  cursor: pointer;
  width: 20%;
  height: 70px;
  transition: all 0.3s ease-in-out;
  /* background: #fff; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.option-card .icon {
  font-size: 24px;
}

.option-card.active-option {
  border-color: #ccc;
  /* background: #f0f8ff; */
}

.radio-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
}

.modal-backdrop.show {
  opacity: 0 !important;
  z-index: 0 !important;
}

.modal-dialog.modal-dialog-centered.modal-dialog-scrollable.paymetpay {
  z-index: 1;
  position: absolute;
  align-items: center;
  justify-content: center;
  place-self: anchor-center;
  padding-top: 3%;

}


/* .option-card input:checked+.radio-button {
  border-color: #007bff;
  background: radial-gradient(circle, #007bff 40%, transparent 50%);
} */


.paymenttababoveheading p,
Link,
h2 {
  /* color: #ffff !important; */
  text-decoration: none !important;
}

.power_play_header_css {
  padding: 0px 0px 0px 25px;
}

.payment_stripe_css label.power_play {
  font-weight: 600;
  font-size: 18px !important;
  /* color: white !important; */
}

.payment_stripe_css p.power_play_OTTs {
  font-weight: 400;
  font-size: 15px;
}

.payment_stripe_css p.power_play_price {
  font-size: 20px;
  font-weight: 600;
}

.payment_stripe_css p.power_play_price_save {
  /* font-weight: 600; */
  font-size: 15px;
}

.payment_stripe_css p.power_play_price_save span {
  /* font-weight: 600; */
  font-size: 15px;
  text-decoration: line-through;
}

.payment_stripe_css .free_video_plans_image {
  font-weight: 600;
  padding: 3px;
  width: 100%;
  height: 100%;
}

.payment_stripe_css .free_video_plans_image img {
  background: #ffff;
  padding: 5px;
}

.payment_stripe_css .free_video_plans_button button {
  /* width: 150px; */
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  /* background: #f60553; */
  /* border: 1px solid #f60553; */
}

.skiptohome label {
  font-weight: 600;
}

.register_Payment .signupnamecss {
  font-size: 18px;
}

.skiptohome button {
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;
}

.skiptohome button:hover {
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  border-radius: 5px;
}

@media (min-width: 320px) {
  .power_play_header_css {
    padding: 0px 0px 0px 15px;
  }

  .payment_stripe_css .free_video_plans_button button {
    /* width: 100%; */
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
  }
}

@media (min-width: 480px) {
  /* smartphones, Android phones, landscape iPhone */
}

@media (min-width: 1281px) {
  .power_play_header_css {
    padding: 0px 0px 0px 25px;
  }

  .payment_stripe_css .free_video_plans_button button {
    /* width: 100%; */
    padding: 10px;
    font-size: 20px;
    font-weight: 600;
  }
}